<template>
  <div class="section">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
      :isOperateColumn="false"
      :exportParams="exportParams"
    >
      <template #searchSlot>
        <v-input label="用户昵称" clearable v-model="searchParam.userName" />
        <v-input label="用户联系电话" clearable v-model="searchParam.phone" />
        <v-select
          clearable
          :options="statusOps"
          v-model="searchParam.type"
          label="商品类型"
        />
        <v-date-picker
          label="下单时间"
          v-model="time"
          type="datetimerange"
          formatValue="yyyy-MM-dd HH:mm:ss"
          startPlaceholder="开始时间"
          endPlaceholder="结束时间"
        />
      </template>
    </v-list>
  </div>
</template>

<script>
import { pageOrderList, exportOrderList } from "./api.js";
import { goodsStatusMap, setgoodsStatusOps } from "./map.js";

export default {
  data() {
    return {
      statusOps: setgoodsStatusOps(),
      searchParam: {
        userName: "",
        phone: "",
        type: "",
        orderStartTime: "",
        orderEndTime: "",
      },
      exportParams: {
        url: exportOrderList,
        methods: "get",
        params: {}
      },
      tableUrl: pageOrderList,
      headers: [
        {
          prop: "id",
          label: "序号",
        },
        {
          prop: "ownName",
          label: "商家名称",
        },
        {
          prop: "goodsName",
          label: "商品名称",
        },
        {
          prop: "type",
          label: "商品类型",
          formatter(row, prop) {
            return goodsStatusMap[row[prop]];
          },
        },
        {
          prop: "receiverName",
          label: "用户昵称",
        },
        {
          prop: "receiverPhone",
          label: "用户联系电话",
        },
        {
          prop: "insertTime",
          label: "下单时间",
        },
        {
          prop: "getAddress",
          label: "取货点",
        },
        {
          prop: "price",
          label: "订单金 (元)",
        },
        {
          prop: "goodsSpecification",
          label: "规格",
        },
      ],
    };
  },
  computed: {
    time: {
      get() {
        return !this.searchParam.orderStartTime &&
          !this.searchParam.orderEndTime
          ? []
          : [this.searchParam.orderStartTime, this.searchParam.orderEndTime];
      },
      set(val) {
        [this.searchParam.orderStartTime, this.searchParam.orderEndTime] =
          val || ["", ""];
      },
    },
  },
  watch: {
    searchParam: {
      handler(val) {
        this.exportParams.params = this.searchParam;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {

  },
};
</script>
<style lang="less" scoped>
.section {
  box-sizing: border-box;
  height: 100%;
}
</style>
